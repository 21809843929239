<template>
  <div>
    <v-dialog :value="true" persistent max-width="1300px">
      <v-card class="pl-5 pr-5 pb-5">
        <v-card-title class="text-h5 mb-3">
          Execute Test Run ({{ testScenarioRun.Number }})<v-spacer></v-spacer>
        </v-card-title>
        <v-card-subtitle class="text-h5">
          {{ testScenarioRun.Name }} ({{ testScenarioRun.FullNumber }})
        </v-card-subtitle>
        <v-card-subtitle>
          <h4>Process</h4>

          <v-row
            ><v-col cols="12" md="10">
              <router-link
                :to="{
                  name: 'processDetail',
                  params: {
                    id: testScenarioRunDetail.process.ProcessId,
                    number: testScenarioRunDetail.process.ProcessNumber,
                  },
                }"
                >{{ $appConfig.customer.prefix
                }}{{
                  testScenarioRunDetail.process.ProcessNumber.toString().padStart(
                    4,
                    '0'
                  )
                }}
                - {{ testScenarioRunDetail.process.ProcessName }}</router-link
              >

              <v-icon small>mdi-chevron-right</v-icon>
              <router-link
                :to="{
                  name: 'processStep',
                  params: { id: testScenarioRunDetail.process.VariantId },
                }"
                >{{ testScenarioRunDetail.process.VariantNumber }} -
                {{ testScenarioRunDetail.process.VariantName }}</router-link
              >
              <v-tooltip
                bottom
                v-if="
                  ($feature('cloudinaryChartIntegration') && processUrl) ||
                  ($feature('lucidChartIntegration') && lucidUrl) ||
                  ($feature('drawIOChartIntegration') && drawingUrl)
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="ml-10"
                    @click="
                      openWindow(
                        $feature('drawIOChartIntegration') && drawingUrl
                          ? drawingUrl
                          : $feature('lucidChartIntegration') && lucidUrl
                          ? lucidUrl
                          : processUrl
                      )
                    "
                  >
                    mdi-image-search-outline
                  </v-icon>
                </template>
                <span>Process Flows</span>
              </v-tooltip></v-col
            ></v-row
          ><v-row
            ><v-col>
              <div v-show="ErpApplications?.length > 0">
                <h4>Application</h4>
                <v-list dense class="list pl-0 ml-0"
                  ><v-list-item v-for="app in ErpApplications" :key="app.id"
                    ><v-list-item-content
                      ><v-tooltip top open-delay="1000">
                        <template v-slot:activator="{ on, attrs }"
                          ><v-list-item-title v-bind="attrs" v-on="on">
                            <a v-if="app.Link" :href="app.Link" target="_blank">
                              <span v-if="app.Customized">*</span>
                              {{ app.Name }}
                            </a>
                            <span v-else>{{ app.Name }}</span>
                          </v-list-item-title></template
                        >{{ app.Name }}</v-tooltip
                      ><v-tooltip top open-delay="1000">
                        <template v-slot:activator="{ on, attrs }"
                          ><v-list-item-subtitle v-bind="attrs" v-on="on"
                            >{{ app.Filter }}
                          </v-list-item-subtitle></template
                        >{{ app.Filter }}</v-tooltip
                      ></v-list-item-content
                    ></v-list-item
                  >
                </v-list>
              </div></v-col
            ></v-row
          >
        </v-card-subtitle>
        <v-card-text>
          <v-row
            ><v-col v-if="testScenarioRun.Description" class="text-pre-wrap"
              ><span v-html="testScenarioRun.Description"></span> </v-col
          ></v-row>

          <v-row
            ><v-col v-if="testScenarioRunDetail.TestInput" class="headline mb-5"
              >Test Input: {{ testScenarioRunDetail.TestInput }}
            </v-col>
            <!-- <v-col text-align="right" cols="12" md="2"
              ><v-btn @click="validateAll">Validate All</v-btn></v-col
            > -->
          </v-row>

          <v-data-table
            v-if="testScenarioRunSteps"
            :headers="headers"
            :items="testScenarioRunSteps"
            sort-by="Number"
            disable-pagination
            hide-default-footer
            show-expand
            single-expand
            :expanded.sync="expanded"
            class="text-pre-wrap"
            :loading="isFindTestScenarioRunStepsPending"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.color`]="{ item }">
              <v-icon
                :id="'expandable-' + item.id"
                small
                v-if="item.GroupIteration > 0"
                :color="item.group?.Color"
                >mdi-circle-multiple</v-icon
              >
              <span v-if="item.GroupIteration > 0" class="caption">{{
                item.GroupIteration + 1
              }}</span>

              <v-icon
                v-if="item.group && item.GroupIteration == 0"
                :color="item.group?.Color"
                >mdi-circle</v-icon
              >
            </template>
            <template v-slot:[`item.Fields`]="i = { item }"
              ><div>
                <v-list
                  dense
                  class="ma-0"
                  v-if="
                    testScenarioRunFieldValues.find(
                      (f) => f.TestScenarioRunStepsId == i.item.id
                    )
                  "
                >
                  <v-list-item
                    v-for="field in listField(i.item.id)"
                    :key="field.id"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ field }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-for="value in listFieldValues(i.item.id, field)"
                        :key="value.id"
                      >
                        {{ value.Name }}
                        <span v-if="value.Code"
                          >({{ value.Code }})</span
                        ></v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div></template
            >
            <template v-slot:[`item.TestRemark`]="{ item }">
              <span v-html="item.TestRemark"
            /></template>
            <template v-slot:[`item.TestResult`]="{ item }"
              ><div>
                <v-icon
                  @click="validateTest(item)"
                  v-if="item.Done && item.blockedBugs == 0"
                  color="green"
                  small
                  class="mr-1"
                  >mdi-check-bold</v-icon
                >
                <v-icon
                  v-if="item.blockedBugs > 0"
                  color="red"
                  small
                  class="mr-1"
                  >mdi-close-thick</v-icon
                ><v-icon
                  @click="validateTest(item)"
                  v-if="!item.Done && item.blockedBugs == 0"
                  color="primary"
                  small
                  class="mr-1"
                  >mdi-checkbox-blank-outline
                </v-icon>
              </div>
            </template>
            <template v-slot:[`item.Issue`]="{ item }">
              <v-icon
                class="ml-2"
                small
                @click="clickAddIssue(item)"
                :color="
                  item.blockedBugs > 0
                    ? 'red'
                    : item.openBugs > 0
                    ? 'orange'
                    : item.issueStatus?.length > 0
                    ? 'green'
                    : 'primary'
                "
              >
                mdi-login-variant
              </v-icon>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td
                :colspan="headers.length"
                v-if="item.process_step?.Description"
              >
                <v-row
                  ><v-col class="mb-0 pb-0"
                    ><span
                      class="ma-3 ql-editor"
                      v-html="item.process_step.Description"
                    ></span></v-col></v-row
                ><v-row
                  ><v-col align="right" class="mt-0 pt-0">
                    <v-btn icon @click="toggle(item.id)">
                      <v-icon small>{{ 'mdi-swap-horizontal' }}</v-icon>
                    </v-btn>
                    <Updated
                      v-if="show[item.id]"
                      class="mr-5 my-2"
                      :updateDate="item.updatedAt"
                      :userId="item.ModifiedById"
                      table="process_step"
                      :id="item.id" /></v-col
                ></v-row>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-text-field
            v-model="testScenarioRunDetail.TestOutput"
            label="Test Output Reference*"
            outlined
            dense
            hide-details
            :rules="[rules.required]"
            @blur="saveOutputReference"
          >
          </v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!this.testScenarioRunDetail.TestOutput && StepValidated"
            color="primary"
            :loading="closing"
            @click="close()"
            >Close</v-btn
          ></v-card-actions
        >
        <v-dialog
          v-if="issueDialog"
          v-model="issueDialog"
          hide-overlay
          width="90vw"
          height="auto"
          ><v-card height="90vh" class="pa-5">
            <IssueList
              v-if="issueDialog"
              v-model="issueDialog"
              :testScenarioRunStep="currentStep"
            />
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import Updated from '@/components/general/Updated.vue';
import IssueList from '@/components/Issues/IssueList.vue';
import { handleErrorResponse } from '@/utils/MessageHandler';
import {
  getDocumentEmbed,
  getDocumentEmbedToken,
} from '@/utils/LucidChartFunctions';

export default {
  props: {
    testScenarioRun: {
      type: Object,
      required: true,
    },
    testScenarioRunDetail: {
      type: Object,
      required: true,
    },

    ro: { type: Boolean, default: false, required: false },
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-run-steps',
      watch: 'testScenarioRunDetail.id',
    }),
    makeFindMixin({
      service: 'test-scenario-run-field-values',
      watch: 'testScenarioRunDetail.id',
    }),
  ],
  watch: {
    expanded(newVal) {
      this.$nextTick(() => {
        if (newVal) {
          this.scrollToExpanded(newVal);
        }
      });
    },
  },
  components: { Updated, IssueList },
  data() {
    return {
      show: [],
      loading: false,
      currentItem: null,
      embedToken: null,
      currentStep: null,
      TestOutput: null,
      StepValidated: false,
      issueDialog: false,
      ErpApplications: [],
      closing: false,
      expanded: null,
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      headers: [
        {
          text: '',
          value: 'color',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Number',
          value: 'Number',
          sortable: false,
          groupable: false,
        },

        {
          text: 'Step',
          value: 'Name',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Fields',
          value: 'Fields',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Test Remark',
          value: 'TestRemark',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Expected Result',
          value: 'ExpectedResults',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Test Result',
          value: 'TestResult',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: 'Issue',
          value: 'Issue',
          sortable: false,
          groupable: false,
          align: 'center',
        },

        { text: '', value: 'data-table-expand' },
      ],
    };
  },
  methods: {
    scrollToExpanded(item) {
      console.log(item);
      const element = document.getElementById('expandable-' + item[0]?.id);
      console.log(element);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    async close() {
      this.closing = true;
      await this.refresh();

      this.$emit('cancel');
      this.ro ? this.$router.go(-1) : '';
      this.closing = false;
    },
    clickAddIssue(item) {
      this.issueDialog = true;
      this.currentStep = item;
    },
    async validateAll() {
      this.testScenarioRunSteps.forEach(async (obj) => {
        await this.validateTest(obj);
      });
    },
    toggle(i) {
      if (this.show[i] == undefined) {
        this.show[i] = true;
        this.show = [...this.show];
      } else {
        this.show[i] = !this.show[i];
        this.show = [...this.show];
      }
    },
    async saveOutputReference() {
      // this.testScenarioRunDetail.TestOutput = this.TestOutput;
      try {
        await this.testScenarioRunDetail.save();
      } catch (error) {
        handleErrorResponse(error);
      }

      this.StepValidated = true;
      await this.refresh();
    },
    async validateTest(item) {
      item.Done = !item.Done;
      try {
        await item.save();
      } catch (error) {
        handleErrorResponse(error);
      }

      this.StepValidated = true;
    },
    async refresh() {
      const { TestScenarioRun, TestScenarioRunDetail } = this.$FeathersVuex.api;

      await TestScenarioRunDetail.find({
        query: { TestScenarioRunId: this.testScenarioRun.id },
      });
      await TestScenarioRun.find({
        query: { id: this.testScenarioRun.id },
      });
    },
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    async LucidEmbed() {
      if (this.testScenarioRunDetail?.process?.ExternalDocumentId) {
        if (!this.testScenarioRunDetail?.process?.EmbedId) {
          await getDocumentEmbed({
            id: this.testScenarioRunDetail?.process?.ProcessId,
            ExternalDocumentId:
              this.testScenarioRunDetail?.process?.ExternalDocumentId,
          });
          // console.log('No Embed');
        }
        this.embedToken = await getDocumentEmbedToken({
          id: this.testScenarioRunDetail?.process?.ProcessId,
          ExternalDocumentId:
            this.testScenarioRunDetail?.process?.ExternalDocumentId,
        });
        // console.log('Embed', this.embedToken);
      } else {
        // console.log('No document Linked');
      }
    },
    listField(step) {
      let fieldObject = [];

      const fields = new Set(); // Create a new Set to store unique values
      this.testScenarioRunFieldValues
        .filter((f) => f.TestScenarioRunStepsId == step)
        .forEach((f2) => (f2.Field ? fields.add(f2.Field) : null));
      fieldObject.push(fields);
      return Array.from(fields);
    },
    listFieldValues(step, field) {
      let values = this.testScenarioRunFieldValues.filter(
        (f) =>
          (f.Field ? f.Field == field : null) &&
          (f.TestScenarioRunStepsId ? f.TestScenarioRunStepsId == step : null)
      );

      return values;
    },
  },
  computed: {
    testScenarioRunStepsParams() {
      return {
        query: { TestScenarioRunDetailId: this.testScenarioRunDetail.id },
      };
    },
    testScenarioRunFieldValuesParams() {
      return {
        query: { TestScenarioRunDetailId: this.testScenarioRunDetail.id },
      };
    },
    processUrl() {
      let url = '';
      if (this.testScenarioRunDetail.process) {
        url =
          'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
          Date.now() +
          '/' +
          this.$appConfig.customer.prefix +
          '/' +
          this.testScenarioRunDetail.process.ProcessNumber.toString() +
          '.' +
          this.testScenarioRunDetail.process.VariantNumber.toString();
      }
      return url;
    },
    drawingUrl() {
      if (this.testScenarioRunDetail.process.NrOfDrawings > 0) {
        return `${this.$appConfig.clientBaseUrl}/#/drawing?variant=${this.testScenarioRunDetail.process.VariantId}`;
      } else {
        return null;
      }
    },

    lucidUrl() {
      if (this.testScenarioRunDetail.process && this.embedToken) {
        return `https://lucid.app/documents/${this.testScenarioRunDetail.process.ExternalDocumentId}/viewer?pages=1
        &token=${this.embedToken}`;
      }
      return null;
    },
  },
  async mounted() {
    this.loading = true;

    if (this.testScenarioRunDetail?.process?.VariantId) {
      const { erpapplication_variant, erp_application } =
        this.$FeathersVuex.api;
      let app_variant = await erpapplication_variant.find({
        query: {
          VariantId: this.testScenarioRunDetail?.process?.VariantId,
        },
      });
      if (app_variant.total > 0) {
        app_variant.data.forEach(async (a) => {
          let app = await erp_application.get(a.ErpApplicationId);
          this, this.ErpApplications.push(app);
        });
      }
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.list {
  background-color: transparent !important;
}
</style>
